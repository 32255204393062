

import React, { useState, useEffect } from "react";

const LoveLetter = () => {
  const [daysCount, setDaysCount] = useState(0);

  useEffect(() => {
    const startDate = new Date("2024-08-15");
    const today = new Date();
    const totalDays = Math.floor((today - startDate) / (1000 * 60 * 60 * 24));

    let currentCount = 0;
    const interval = setInterval(() => {
      if (currentCount <= totalDays) {
        setDaysCount(currentCount);
        currentCount++;
      } else {
        clearInterval(interval);
      }
    }, 50);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>que pasa chaval</h1>
      <p style={styles.counter}>días desde que me rompes los huevos: {daysCount}</p>
      <div style={styles.textContainer}>
        <p>Bueno, esta va la versión tres de la página que sinceramente hice solo por vos y para vos.</p>
        <p>No se me ocurren muchas cosas para decirte, pero bueno, sinceramente esto va a ser algo corto o eso creo.</p>
        <p>
          Perdona si a veces te pido que dejes de mandar ciertos stickers. Es que me hace
          poner mal porque siento que te hago mal por lo anterior pasado en noviembre, que sinceramente
          no me gustaría que vuelva a pasar por muchas razones. Me afectó mucho a mí, y no quiero que vuelva a pasar.
          Espero decirte con esto que no me gustaría volver a verte en esa situación porque sinceramente me duele verte así,
          y más sabiendo que en parte puedo ser responsable de que te sientas mal o tengas algún tipo de recaída.
        </p>
        <p>
          Vos sabés que te quiero mucho, y sinceramente lo último que quiero es hacerte mal. Me encantaría poder
          encontrar la manera de que todo sea mejor para vos, de hacerte sentir tranquila, cuidarte, y demostrarte
          que sinceramente estoy para vos siempre, aunque a veces me equivoque o no lo haga de la mejor forma.
        </p>
        <p>
          No sé si todo lo que estoy diciendo tiene mucho sentido, pero bueno, siento que necesitaba decírtelo para
          que entiendas que me importás y que siempre voy a querer lo mejor para vos.
        </p>
        <p>
          Y bueno, sé que esto no es mucho, pero lo que intento transmitir es que te valoro muchísimo y que no quiero
          que pienses que algo de lo que pasó en el pasado puede cambiar eso. Te quiero mucho y sinceramente quiero que
          seas feliz, que te sientas bien conmigo y con vos misma, porque te lo merecés más que nadie.
        </p>
        <p>
          En fin, espero que todo esto que te escribí sirva para que sepas que acá estoy, siempre para vos, y que no hay
          nada que me importe más que verte bien. Y gracias, gracias por ser vos y por dejarme estar con vos, porque sos
          lo mejor que tengo y siempre voy a querer que estés bien.
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    margin: '0',
    padding: '20px',
    backgroundColor: '#fdf5f5',
    color: '#4a2f6b',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    fontSize: '2.5rem',
    margin: '10px 0',
    color: '#b83280',
  },
  counter: {
    textAlign: 'center',
    fontSize: '1.8rem',
    margin: '10px 0',
    fontWeight: 'bold',
  },
  textContainer: {
    maxWidth: '600px',
    textAlign: 'justify',
    lineHeight: '1.8',
    fontSize: '1rem',
    padding: '10px 20px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
};

export default LoveLetter;
